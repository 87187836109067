import React, { useState } from "react";
import App_url from "../components/common/Constant";
import InputGroup from "../components/common/InputGroup";
import { useDispatch } from "react-redux";
import { setShowSuccessModal } from "../redux/actions/adminActions";
import SignUpSuccess from "../components/common/Modal/SignUpModal";
import utils from "../components/common/Utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Icon from "../components/common/Icon";
import { PostRequestAPI } from "../components/common/PostRequest";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    country_code: 1,
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    country_code: "",
  });
  const handleOnChange = (value, data) => {
    const numberWithoutCountryCode = value.replace(data.dialCode, "");
    setError((prev) => ({ ...prev, mobile: "" }));
    setFormData((prev) => ({
      ...prev,
      country_code: data?.dialCode,
      mobile: numberWithoutCountryCode,
    }));
  };
  const changeHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    setError((data) => ({
      ...data,
      [e.target.name]: "",
    }));
  };

  const validation = () => {
    let val = true;
    const email = utils.ValidateEmail(formData?.email);
    if (email) {
      error.email = email;
      val = false;
    }
    const password = utils.checkPasswordValidity(formData?.password);
    if (password) {
      error.password = password;
      val = false;
    }
    if (formData?.mobile?.length < 7) {
      error.mobile = "Please enter valid mobile number";
    }
    if (!formData?.name) {
      error.name = "Please enter valid user name";
    }
    setError((data) => ({
      ...data,
      ...error,
    }));
    return val;
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const validate = validation();
    if (validate) {
      const payload = {
        email: formData?.email,
        full_name: formData?.name,
        password: formData?.password,
        mobile_number: formData?.mobile,
        country_code: formData?.country_code,
      };
      try {
        const response = await PostRequestAPI(App_url?.api?.signUp, payload);
        if (response?.status === 200) {
          setFormData({
            name: "",
            email: "",
            mobile: "",
            password: "",
          });
          toast.success(response?.data?.message);
          dispatch(
            setShowSuccessModal({ show: "SIGNINSUCCESS", data: formData })
          );
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const [password, setPassword] = useState(true);

  return (
    <div className="bg-sign-in py-5">
      <div className="row m-0 d-center login-form-row overflow-x-hidden">
        <div className="col-md-6 col-lg-3 bg-white br-15px pb-4">
          <div className="d-center flex-column">
            <div className="logo-image d-center flex-column w-100">
            <div
                className="d-flex position-relative w-100 jcc mt-4"
              >
                <img src={process.env.REACT_APP_LOGO} alt="logo" loading="lazy" height={150} width={210} className="br-6px" />
                <img src={App_url?.image?.gunShot} className=" mb-4 position-absolute" alt="gunShot" loading="lazy" style={{right:"-35px"}} />
              </div>
              <h5 className="fw-600 mt-2 txt">Sign Up</h5>
              <p className="fw-400">
                Already have an account?{" "}
                <span
                  className="border-bottom-text fw-500 pointer"
                  onClick={() => {
                    navigate(App_url?.link?.signIn);
                  }}
                >
                  {" "}
                  Sign in{" "}
                </span>
              </p>
            </div>
            <div className="mt-2">
              <img
                src={App_url?.image?.gunShot}
                alt=""
                style={{ marginLeft: "-6rem", marginTop: "-2.5rem" }}
              />
              <img
                src={App_url?.image?.bulletTravel}
                alt=""
                style={{ marginLeft: "-1.5rem" }}
              />
            </div>
            <div className="my-1 w-100 px-4">
              <InputGroup
                type={"text"}
                onChange={changeHandler}
                name={"name"}
                value={formData?.name}
                label={"Full name"}
                placeholder={"Enter your full name"}
                error={error?.name}
              />
            </div>
            <div className="my-1 w-100 px-4">
              <InputGroup
                type={"email"}
                onChange={changeHandler}
                name={"email"}
                value={formData?.email}
                label={"Email Address"}
                placeholder={"Enter your email address"}
                error={error?.email}
              />
            </div>
            <div className="my-1 w-100 px-4">
              <div className="mb-2">
                <label htmlFor="mobile" className="form-label fs-16px">
                  Mobile Number
                </label>
                <PhoneInput
                  country={"us"}
                  value={`${formData?.country_code || 1}${formData?.mobile}`}
                  onChange={handleOnChange}
                  inputStyle={{ width: "100%" }}
                  defaultErrorMessage={error?.mobile}
                  countryCodeEditable={false}
                />
              </div>
              {error?.mobile && (
                <div className="text-error">{error?.mobile}</div>
              )}

              <div className="my-1 w-100 px-0">
                <InputGroup
                  type={password ? "password" : "text"}
                  onChange={changeHandler}
                  name={"password"}
                  value={formData?.password}
                  label={"Password"}
                  placeholder={"Minimum 8 Characters"}
                  error={error?.password}
                  rightLabel={
                    <Icon
                      attrIcon={
                        !password ? App_url.image.Eye : App_url.image.EyeSlash
                      }
                    />
                  }
                  rightIconClick={() => setPassword(!password)}
                />
              </div>
            </div>
            <div className="w-100 px-4">
              <button
                className="yellow-bg fw-500 w-100 btn mt-3"
                onClick={submitHandler}
              >
                SIGN UP
              </button>
            </div>
          </div>
        </div>
      </div>
      <SignUpSuccess />
    </div>
  );
};

export default SignUp;
